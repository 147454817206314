import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  TextInput,
  required,
  SelectInput,
  TextField,
  TabbedForm,
  FormTab,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { ImageUploaderWithPreview } from 'components/common/ImageUploaderWithPreview';
import { Box } from '@material-ui/core';

export const BrandsEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <FormTab label='General'>
        {/* Fields saved on database, cannot be changed */}
        <TextField source='name' fullWidth />
        <TextField source='public_id' label='Wallet address' fullWidth />
        <TextField source='app_id' label='App Id' fullWidth />
        <TextInput source='site_url' label='site URL' fullWidth />

        <TextInput source='email' validate={required()} fullWidth />
        <TextInput source='description' multiline fullWidth />
        <SelectInput
          fullWidth
          defaultValue={false}
          source='is_mfa_enabled'
          label='Multi-factor authentication'
          choices={[
            { id: true, name: 'enable' },
            { id: false, name: 'disable' },
          ]}
        />
        <ImageUploaderWithPreview
          source='thumbnail'
          accept='image/*'
          label='Thumbnail'
        />
      </FormTab>
      <FormTab label='Customizations'>
        <ColorInput label='Background color' source='background_color' />
        <Box sx={{ width: '100%', minHeight: '30px', fontSize: '11px' }}>
          Should be saved in HEX format. Eg: #2d4257
        </Box>
        <ImageUploaderWithPreview
          source='page_background'
          accept='image/*'
          label='Page background image'
          helperText='Recommended size: 1920x1080'
        />
        <ImageUploaderWithPreview
          source='header_background'
          accept='image/*'
          label='Header background image'
          helperText='Header height: 120px. Recommended size: 1920x120px'
        />
      </FormTab>
      <FormTab label='Popup'>
        <ImageUploaderWithPreview
          source='popup_banner'
          accept='image/*'
          label='Popup banner image'
          helperText='Container height: 220px. Dimensions on desktop: 390x220px. (on mobile width will depend on device)'
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);
