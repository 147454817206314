import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  UrlField,
  Labeled,
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import { Divider, Typography } from '@material-ui/core';
import { ContainedImage } from 'components/common/Image';
import { ColorField } from 'react-admin-color-input';

export const BrandsShow = (props) => {
  return (
    <Show title={<ViewTitle source='name' />} {...props}>
      <SimpleShowLayout>
        <TextField source='id' label='ID' />
        <TextField source='name' label='Name' />
        <TextField source='app_id' label='App Id' />
        <TextField source='email' label='Email' />
        <TextField source='public_id' label='Wallet Address' />
        <TextField source='status' label='Status' />
        <UrlField source='site_url' label='Site URL' />
        <TextField source='description' label='Description' />
        <BooleanField source='is_mfa_enabled' />
        <ContainedImage source='thumbnail_url' label='Thumbnail' />
        <DateFieldShow source='created_at' label='Created At' />
        <DateFieldShow source='updated_at' label='Updated At' />
        <Divider />
        <Typography
          variant='h6'
          component='h1'
          style={{
            margin: '20px 0 5px 0',
          }}
        >
          Customizations
        </Typography>
        <ColorField label='Background color' source='background_color' />

        <Labeled label='Page background'>
          <ContainedImage
            source='page_background_url'
            label='Page background'
          />
        </Labeled>

        <Labeled label='Header background'>
          <ContainedImage
            source='header_background_url'
            label='Header background'
          />
        </Labeled>
        <Divider />
        <Typography
          variant='h6'
          component='h1'
          style={{
            margin: '20px 0 5px 0',
          }}
        >
          Popup
        </Typography>
        <Labeled label='Popup banner image'>
          <ContainedImage
            source='popup_banner_url'
            label='Popup banner image'
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};
